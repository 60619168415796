interface StorageData<T = any> {
    data: T
    expire: number | null
  }
  
  export function createLocalStorage(options?: { expire?: number | null }) {
    const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7
  
    const { expire } = Object.assign(
      {
        expire: DEFAULT_CACHE_TIME,
      },
      options,
    )
  
    function set<T = any>(key: string, data: T) {
      const storageData: StorageData<T> = {
        data,
        expire: expire !== null ? new Date().getTime() + expire * 1000 : null,
      }
  
      // 始终使用 JSON.stringify 进行存储
      const json = JSON.stringify(storageData)
      window.localStorage.setItem(key, json)
    }
  
    function get(key: string) {
      const json = window.localStorage.getItem(key)
      if (json) {
        let storageData: StorageData | null = null
  
        try {
          // 始终使用 JSON.parse 进行获取
          storageData = JSON.parse(json)
        }
        catch {
          // Prevent failure
        }
  
        if (storageData) {
          const { data, expire } = storageData
          if (expire === null || expire >= Date.now())
            return data
        }
  
        remove(key)
        return null
      }
    }
  
    function remove(key: string) {
      window.localStorage.removeItem(key)
    }
  
    function clear() {
      window.localStorage.clear()
    }
  
    return {
      set,
      get,
      remove,
      clear,
    }
  }
  
  // 创建 localStorage 实例
  export const ls = createLocalStorage()
  
  // 创建 sessionStorage 实例，过期时间为 null，不使用加密
  export const ss = createLocalStorage({ expire: null })
  