import { defineStore } from 'pinia'
import { ref } from 'vue'
import { fetchLogin } from '@/api/user'
import { store } from '@/store'

// 定义 fetchLogin 返回值类型
interface FetchLoginResponse {
  data: {
    userInfo: any; // 根据实际数据结构定义
    userBalance: any; // 根据实际数据结构定义
  };
}

export const useAuthStore = defineStore('auth-store', {
  state: () => ({
    token: '',
    guestid: '',
    loginDialog: false,
    globalConfigLoading: false,
    userInfo: {
      enterprise_name:""
    },
    userBalance: 0,
    globalConfig: {},
  }),

  getters: {
    // 如果有 token 属性，可以取消注释并实现
    // isLogin: (state) => !!state.token,
  },

  actions: {
    setToken(newToken: string) {
      this.token = newToken;
    },
    setUserInfo(userInfo: string) {
      console.log(userInfo)
      this.userInfo = userInfo;
    },
    // async getUserInfo(): Promise<any> {
    //   try {
    //     const res = await fetchLogin() as FetchLoginResponse; // 类型断言
    //     if (!res)
    //       return Promise.resolve(res);
    //     const { userInfo, userBalance } = res.data;
    //     this.userInfo = { ...userInfo };
    //     this.userBalance = { ...userBalance };
    //     return Promise.resolve(res.data);
    //   } catch (error) {
    //     return Promise.reject(error);
    //   }
    // },
  },
});

export function useAuthStoreWithout() {
  return useAuthStore(store);
}
