<script setup lang="ts">
import { NSpace, NLayout, NLayoutSider, NMenu } from 'naive-ui'
import type { Component } from 'vue'
import {defineComponent, h, onMounted, ref} from 'vue'
import { NIcon } from 'naive-ui'
import HeaderCom from '@/components/HeaderCom.vue'
import FooterCom from '@/components/FooterCom.vue'
import Footers from '@/components/Footer.vue'
import { useAuthStore } from '@/store'
const authStore = useAuthStore()
import { useRouter,useRoute } from 'vue-router'
const router = useRouter()
const route=useRoute()
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
  HomeOutlined,
  UserOutlined,
  EuroOutlined,
  InsertRowLeftOutlined
} from '@ant-design/icons-vue';
import {getCurrentTimestamp} from "@/utils/solt/timestamp";
import {sortByMd5WithSalt} from "@/utils/solt";
import {fetchMenuData, fetchProgressList} from "@/api/apply";
console.log(authStore.userInfo)
authStore.userInfo=JSON.parse(localStorage.getItem('UserInfo'))
async function getMenu(){
    const time = await getCurrentTimestamp()
    const obj = {
        timestamp: time
    }
    const sign = await sortByMd5WithSalt(obj)
    const res = await fetchMenuData({
        timestamp: time,
        sign: sign
    })
    let arr=[]
    res?.data.forEach((item,index)=>{
        if(item.children.length===0){
            delete item.children;
        }
        if(item.label==='首页'){
            item.icon=() => h(InsertRowLeftOutlined)
        }else if(item.label=='拿帐户'){
            item.icon=() => h(AppstoreOutlined)
        }else if(item.label=='管帐户'){
            item.icon=() => h(SettingOutlined)
        }else if(item.label=='管资金'){
            item.icon=() => h(EuroOutlined)
        }
        if(item.label=='企业信息'){
            // res?.data.splice(index,1)
            item.icon= () => h(InsertRowLeftOutlined)
            menuOptions1.value=[item];
        }else{
            arr.push(item)
        }
    })
    console.log("菜单列表",res?.data)
    menuOptions.value=arr
}
onMounted(()=>{
    getMenu()
})
const menuOptions = ref([
  {
    key: 'index',
    icon: () => h(HomeOutlined),
    label: '首页',
    title: 'index',
  },
  {
    key: '2',
    icon: () => h(AppstoreOutlined),
    label: '拿账户',
    title: 'dance',
    children: [
      {
        key: 'countApply',
        label: '开户申请',
        title: 'countApply',
      },
      {
        key: 'countProgress',
        label: '开户进度',
        title: 'countProgress',
      },

    ],
  },
  {
    key: 'sub2',
    icon: () => h(SettingOutlined),
    label: '管账户',
    title: '管账户',
    children: [
      {
        key: 'CountList',
        label: '账户列表',
        title: 'countList',
      },
      {
          key: 'ChargeReport',
          label: '账户消耗报告',
          title: 'chargeReport',
      },
      {
        key: 'chargeProgress',
        label: '充值进度',
        title: 'chargeProgress',
      },
      {
        key: 'ChargeRecord',
        label: '充值记录',
        title: 'chargeRecords',
      }

    ],
  },
  {
    key: 'sub3',
    icon: () => h(EuroOutlined),
    label: '管资金',
    title: '管资金',
    children: [
      {
        key: 'Wallet',
        label: '钱包流水',
        title: 'wallet',
      },
      {
        key: 'WalletProgress',
        label: '钱包充值进度',
        title: 'walletProgress',
      },
      {
        key: 'WalletRecords',
        label: '账单管理',
        title: 'walletRecords',
      }

    ],
  },

]);
const menuOptions1 = ref([
  // {
  //   key: '33',
  //   icon: () => h(InsertRowLeftOutlined),
  //   label: '企业信息',
  //   title: 'compony',
  //   mode: "vertical",
  //   children: [
  //     {
  //         key: 'CompanyInfo',
  //         label: '企业信息',
  //         title: 'CompanyInfo',
  //     },
  //     {
  //         key: 'CompanyList',
  //         label: '企业列表',
  //         title: 'CompanyList',
  //     },
  //     {
  //       key: 'Team',
  //       label: '团队管理',
  //       title: 'Team',
  //     }
  //
  //
  //   ],
  // },
])
const selectedKeys = ref([route.name]);
const openKeys = ref(['index','2','sub2','sub3']);

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}
defineProps<{ msg: string }>()

const count = ref(0)
function jumpLogin() {
  router.push('/login')
}

function getKeys(item: any) {
  router.push(item.key)
}
</script>

<template>
  <div style="margin-top: 64px;">

    <a-button
      style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;height: 50px;margin: 10px 20px;">
      <div style="display: flex;align-items: center;">
<!--        <div style="margin-top: 3px;margin-right: 2px;">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <g id="Frame">-->
<!--              <g id="Vector">-->
<!--                <path-->
<!--                  d="M22.0403 9.03255L17.8078 3.76018C17.3846 3.22085 16.6929 2.875 15.9988 2.875H7.45417C6.76248 2.875 6.10706 3.18215 5.64512 3.76018L1.41272 9.03255C0.682324 9.91772 0.757298 11.2261 1.52881 12.075L10.1121 21.159L10.2282 21.2751C10.6515 21.6596 11.2295 21.8918 11.8051 21.8918H11.8825C12.4992 21.8531 13.0748 21.6234 13.4981 21.1614L21.8879 12.1162C22.6957 11.2261 22.7344 9.91772 22.0403 9.03255ZM16.9614 11.6131L12.9587 15.9616C12.342 16.5783 11.3818 16.617 10.7651 16.039L10.6877 15.9616L6.5303 11.5744C6.26185 11.2673 6.26185 10.7666 6.569 10.4958C6.91484 10.1886 7.37678 10.2273 7.68393 10.5344L11.5898 14.6604C11.7059 14.7814 11.897 14.7814 12.0131 14.6604L15.8053 10.6143C16.1125 10.3071 16.5744 10.2684 16.9203 10.5369C17.2298 10.8029 17.2298 11.3035 16.9614 11.6131Z"-->
<!--                  fill="url(#paint0_linear_5479_4296)"></path>-->
<!--                <path-->
<!--                  d="M16.9614 11.6131L12.9587 15.9616C12.342 16.5783 11.3818 16.617 10.7651 16.039L10.6877 15.9616L6.5303 11.5744C6.26185 11.2673 6.26185 10.7666 6.569 10.4958C6.91484 10.1886 7.37678 10.2273 7.68393 10.5344L11.5898 14.6604C11.7059 14.7814 11.897 14.7814 12.0131 14.6604L15.8053 10.6143C16.1125 10.3071 16.5744 10.2684 16.9203 10.5369C17.2298 10.8029 17.2298 11.3035 16.9614 11.6131Z"-->
<!--                  fill="white"></path>-->
<!--              </g>-->
<!--            </g>-->
<!--            <defs>-->
<!--              <linearGradient id="paint0_linear_5479_4296" x1="11.7174" y1="2.875" x2="11.7174" y2="21.8918"-->
<!--                gradientUnits="userSpaceOnUse">-->
<!--                <stop stop-color="#B075FA"></stop>-->
<!--                <stop offset="1" stop-color="#7C4FB6"></stop>-->
<!--              </linearGradient>-->
<!--            </defs>-->
<!--          </svg>-->
<!--        </div>-->
        <div style="font-size: 16px;width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
          {{ authStore?.userInfo?.enterprise_name }}
        </div>
      </div>
    </a-button>
    <a-menu v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys" style="font-size: 16px;width: 256px" mode="inline"
      :items="menuOptions" @click="getKeys" />
    <div class="team">
      <a-menu :forceSubMenuRender="true" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys" style="font-size: 16px;width: 256px" mode="vertical"
        :items="menuOptions1" @click="getKeys" />
    </div>


  </div>

</template>

<style scoped>


.team {
  position: fixed;
  left: 0;
  bottom: 0;
}

.title {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 10px;
  width: 82%;
  color: #000000e0;
  font-size: 16px;
  height: 50px;
  margin: 0 10px;
  overflow: hidden;
  /* 隐藏超出容器的内容 */
  white-space: nowrap;
  /* 防止文本换行 */
  text-overflow: ellipsis;
  /* 添加省略号 */
  line-height: 50px;

}

.men {
  text-align: left;
}

:where(.css-dev-only-do-not-override-19iuou).ant-menu-light {
  color: rgba(0, 0, 0, 0.88);
  background: #F0f2f5;
  text-align: left;
  font-size: 16px;
}

:global(.n-layout-scroll-container) {
  color: rgba(0, 0, 0, 0.88);
  background: #F0f2f5;
}

/deep/ :where(.css-dev-only-do-not-override-19iuou).ant-menu-inline .ant-menu-item{
    height:50px !important;
    line-height:50px !important;
}

</style>
<style>
html,body{
    overflow: hidden !important;
}
</style>