import md5 from 'md5';

/**
 * 对对象数组进行 MD5 加密并排序
 * @param {Array} arr - 包含待加密对象的数组
 * @param {string} saltKey - 用于盐值的键名
 * @returns {Array} - 按 MD5 加密值排序后的数组
 */
export function sortByMd5WithSalt(obj) {
    const yan = 'f0c6b41a54984336'
    const sortedKeys = Object.keys(obj).sort();
    // 构建排序后的键值对
    const sortedEntries = Object.keys(obj).sort().map(key => {
        return `${key}${obj[key]}`;
    });
    const resultString = sortedEntries.join('');
    // 构建排序后的键值对
    // const sortedEntries = sortedKeys.map(key => `${key}: ${obj[key]}`);
    console.log(sortedEntries)
    // const resultString = sortedEntries.join('').replace(/[:\s]/g, '');
    const ass = yan + resultString;
    console.log(ass)
    const final = md5(yan+resultString)
    return final;
}

