import type { AxiosProgressEvent, AxiosResponse, GenericAbortSignal } from 'axios'
import request from './axios'
import { useMessage } from 'naive-ui'
const ms = useMessage();
import { useAuthStore } from '@/store'
import {message} from "ant-design-vue";
export interface HttpOption {
  url: string
  data?: any
  method?: 'GET' | 'POST' // Specify allowed methods
  headers?: Record<string, string> // Define headers as a record
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: GenericAbortSignal
  beforeRequest?: () => void
  afterRequest?: () => void
}

// Define the expected structure of the API response data
export interface ApiResponseData {
  success: boolean; // Assuming success is a boolean
  message?: string; // Optional message field
}

export interface Response<T = any> {
  request: any;
  data?: T;
  message?: string | null;
  response?: {
    status?: number; // Changed to number for consistency
    data?: ApiResponseData; // Use the new ApiResponseData type here
  } | null; // Allow for null if the response is not present
  code?: number; // Added code property
}

let last401ErrorTimestamp = 0
const homePagePath = ['/chatlog/chatList', '/group/query']

function hasWhitePath(path: string): boolean {
  if (!path) return false
  return homePagePath.some(item => path.includes(item))
}

async function http<T = any>(
  { url, data, method = 'GET', headers, onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
): Promise<Response<T>> {
  const successHandler = (res: AxiosResponse<Response<T>>) => {
    const { code } = res.data;

    if ((code >= 200 && code < 300) || !code) {
      return res;
    }

    if (code === 401) {
      // window.location.reload();
      message.info("登录过期,请重新登录")
      location.href="/login"
    }

    return Promise.reject(res);
  }

  const failHandler = (error: any) => { // Changed to any for better flexibility
    let data: ApiResponseData | string = ''; // Declare data with ApiResponseData or string
    if (typeof error?.response?.data === 'object') {
      data = error.response.data; // Ensure data is of the correct type
    }

    afterRequest?.();
    const status = error?.response?.status || 0; // Default to 0 if undefined
    
    const authStore = useAuthStore(); // Move inside the handler to avoid unused variable warning

    if (status === 401) {
      if (!hasWhitePath(error?.request?.responseURL)) {
        let message = '请先登录后再进行使用！'; // Default message
        if (typeof data === 'object' && 'message' in data) {
          message = data.message || message; // Safely access message if data is of type ApiResponseData
        }
        Date.now() - last401ErrorTimestamp > 3000 && ms.error(message);
      }
      last401ErrorTimestamp = Date.now();
    } else if (status === 430) {
      if (typeof data === 'object' && 'success' in data && !data.success) {
        ms.error(data.message || '请求接口错误！');
      }
    } else if (status === 420) {
      console.log(error);
      return error;
    } else {
      if (typeof data === 'object' && 'success' in data && !data.success) {
        ms.error(data.message || '请求接口错误！');
      }
    }
    // console.log(error?.data?.msg)
    return error
    // throw new Error(error?.message || 'Error');
  }

  beforeRequest?.();

  const params = typeof data === 'function' ? data() : data ?? {};

  return method === 'GET'
    ? request.get(url, { params, signal, onDownloadProgress }).then(successHandler).catch(failHandler)
    : request.post(url, params, { headers, signal, onDownloadProgress }).then(successHandler).catch(failHandler);
}

export function get<T = any>(
  { url, data, method = 'GET', onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
  });
}

export function post<T = any>(
  { url, data, method = 'POST', headers, onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
  });
}

export default post;
