<script setup lang="ts">
import { NSpace, NLayout, NLayoutSider, NMenu,NBreadcrumb,NBreadcrumbItem } from 'naive-ui'
import type { Component } from 'vue'
import { defineComponent, h, ref ,onMounted} from 'vue'
import { NIcon } from 'naive-ui'
import {UserOutlined} from '@ant-design/icons-vue';
import { useRouter,useRoute } from 'vue-router'
import {getCurrentTimestamp} from "@/utils/solt/timestamp";
import {sortByMd5WithSalt} from "@/utils/solt";
import {fetchApplyAccount} from "@/api/apply";
import {message} from "ant-design-vue";
import {logoutUser} from "@/api/user";
const router = useRouter()
const route=useRoute()
const isDropdownVisible = ref(false)
function showDropdown() {
  isDropdownVisible.value = true;
}
function hideDropdown() {
  isDropdownVisible.value = false;
}
function viewProfile (){
  router.push('/User')
}
async function logout (){
    const time = await getCurrentTimestamp()
    const obj = {
        timestamp: time
    }
    const sign = await sortByMd5WithSalt(obj)
    const res =await logoutUser({...obj,sign})
    if (res?.code == 200) {
        message.success(res?.msg)
        localStorage.removeItem('token')
        localStorage.removeItem('UserInfo')
        router.push('/login')
    }else{
        message.info(res?.msg)
    }
}
const menuList=ref({index:['首页'],countApply:['拿账户','开户申请'],countProgress:['拿账户','开户进度'],CountList:['拿账户','账户列表'],chargeProgress:['管账户','充值进度'],ChargeRecord:['管账户','充值记录'],Wallet:['管资金','钱包流水'],WalletProgress:['管资金','钱包充值进度'],WalletRecords:['管资金','账单管理'],
    ChargeReport:['管账户','账户消耗报告'],CompanyList:['企业信息','企业列表'],Team:['企业信息','团队管理'],CompanyInfo:['企业信息'],User:['帮助中心','我的资料']
})
onMounted(()=>{

})
</script>

<template>
  <div class="mainBackColorHeader">
      <div style="display: flex;align-items: center;width: 258px;font-size: 25px;font-weight: bold;justify-content: center">
          <img src="@/assets/logo.png" style="object-fit: contain;height: 60px;margin-right: 20px;" />
          <div>PanoMobi</div>
      </div>
    <div class="main_title">
        <n-breadcrumb>
            <n-breadcrumb-item v-for="(item,i) in menuList[route.name]" :key="i">
                 {{item}}
            </n-breadcrumb-item>
        </n-breadcrumb>
    </div>
      <div style="position: absolute;right: 0px;">
          <div class="use_info" @mouseenter="showDropdown" @mouseleave="hideDropdown">
              <UserOutlined  /><!--@click="jumpLogin"-->
              <div v-if="isDropdownVisible" class="dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
                  <ul>
                      <li @click="viewProfile">我的资料</li>
                      <li @click="logout">账号退出</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
  
</template>

<style scoped>
.use_info {
  position: relative;
  top: 12px;
  right: 4.5rem;
  z-index: 99;
  border: 1px solid #aaa;
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  background: #1981fe;
  cursor: pointer;
  color: #ffffffd1;
  text-align: center;
}
.mainBackColorHeader {
  /* background: linear-gradient(to right, #EAFBF9, #F1FBFC, #F0F7FE, #F6F2FB); */
  height: 64px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  display: flex;
  /*justify-content: space-between;*/
}

.main_title {
  transition: color 0.2s;
  padding: 0 4px;
  border-radius: 4px;
  height: 22px;
  display: inline-block;
  margin-inline: -4px;
  font-weight: 700;
  font-size: 1rem;
  color: black;
  line-height: 64px;
  margin-left: 15px;
}
/deep/ nav{
    padding:30px 0px;
}
.dropdown {
  position: absolute;
    top: 29px;
    left: -40px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 4px;
    margin-top: 5px;
    width: 93px;
    color: #000000e0;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown li:hover {
  color: #000000e0;
  background-color: #f5f5f5;
}
</style>
