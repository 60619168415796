import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { Layout } from '@/layout'
const StaticHome = {
  template: `<div><h1>Static Home Page</h1></div>`
};
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: { name: 'index' }, // 使用命名路由进行重定向
    children: [
      {
        name: 'index',
        path: '/index',
        meta: { title: '首页' },
        // component: StaticHome
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/countApply',
        name: 'countApply',
        meta: { title: '首页' },
        component: () => import('../views/CountApply.vue')
      },
      {
        path: '/countProgress',
        name: 'countProgress',
        meta: { title: '开户进度' },
        component: () => import('../views/AboutView.vue') // 确认 AboutView.vue 是否是你想要的组件
      },
      {
        path: '/AccountCount',
        name: 'AccountCount',
        meta: { title: '帐户统计' },
        component: () => import('../views/AccountCount.vue')
      },
      {
        path: '/chargeProgress',
        name: 'chargeProgress',
        meta: { title: '充值进度' },
        component: () => import('../views/ChargeProgress.vue')
      },
      {
        path: '/ChargeRecord',
        name: 'ChargeRecord',
        meta: { title: '充值记录' },
        component: () => import('../views/ChargeRecord.vue')
      },
      {
        path: '/ChargeReport',
        name: 'ChargeReport',
        meta: { title: '帐户消耗报告' },
        component: () => import('../views/ChargeReport.vue')
      },
      {
        path: '/CountList',
        name: 'CountList',
        meta: { title: '帐户列表' },
        component: () => import('../views/CountList.vue')
      },
      {
        path: '/CompanyConsume',
        name: 'CompanyConsume',
        meta: { title: '企业消耗' },
        component: () => import('../views/CompanyConsume.vue')
      },
      {
        path: '/Wallet',
        name: 'Wallet',
        meta: { title: '钱包流水' },
        component: () => import('../views/Wallet.vue')
      },
      {
        path: '/CompanyInfo',
        name: 'CompanyInfo',
        meta: { title: '企业信息' },
        component: () => import('../views/CompanyInfo.vue')
      },
      {
        path: '/CompanyList',
        name: 'CompanyList',
        meta: { title: '企业列表' },
        component: () => import('../views/CompanyList.vue')
      },
      {
        path: '/Team',
        name: 'Team',
        meta: { title: '团队管理' },
        component: () => import('../views/Team.vue')
      },
      {
        path: '/User',
        name: 'User',
        meta: { title: '我的资料' },
        component: () => import('../views/User.vue')
      },
      {
        path: '/WalletProgress',
        name: 'WalletProgress',
        meta: { title: '钱包充值进度' },
        component: () => import('../views/WalletProgress.vue')
      },
      {
        path: '/WalletRecords',
        name: 'WalletRecords',
        meta: { title: '账单管理' },
        component: () => import('../views/WalletRecords.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登陆' },
    component: () => import('../views/Login.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
