<template>
    <footer class="ant-layout-footer" style="margin-top: 30px;position: static;bottom: 0px;" id="foor">
        <!-- <section  class="flex flex-col items-center justify-center text-xs" style="display: flex;justify-content: center;align-items: center;font-size: 12px;">
                <a-button  class="css-19iuou ant-btn ant-btn-link !cursor-pointer"
                    type="a-button"><span style="color: #0000004d!important">联系我们</span></a-button>
                <div  class="ant-divider css-19iuou ant-divider-vertical" role="separator">
                </div><a-button  class="css-19iuou ant-btn ant-btn-link !cursor-pointer"
                    type="a-button"><span style="color: #0000004d!important">关于我们</span></a-button>
                <div  class="ant-divider css-19iuou ant-divider-vertical" role="separator">
                </div><a-button  class="css-19iuou ant-btn ant-btn-link !cursor-pointer"
                    type="a-button"><span style="color: #0000004d!important">合作伙伴</span></a-button>
                <div  class="ant-divider css-19iuou ant-divider-vertical" role="separator">
                </div><a-button  class="css-19iuou ant-btn ant-btn-link !cursor-pointer"
                    type="a-button"><span style="color: #0000004d!important">用户协议</span></a-button>
                <div  class="ant-divider css-19iuou ant-divider-vertical" role="separator">
                </div><a-button  class="css-19iuou ant-btn ant-btn-link !cursor-pointer"
                    type="a-button"><span style="color: #0000004d!important">广告协议</span></a-button>
        </section> -->
        <div style="color: #0000004d!important;text-align: center;"  class="text-black/30"><span > Copyright © 2024 PanoMobi Co., Limited
                    保留所有权利 </span><a  class="ant-btn" target="_blank" rel="noreferrer"
                    href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备14059360号-17</a></div>
    </footer>
</template>
<style scope>
#foor a{
   color: #0000004d!important;
   text-decoration: none;
}
</style>
