import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "mainBackColorHeader" }
const _hoisted_2 = { class: "main_title" }
const _hoisted_3 = { style: {"position":"absolute","right":"0px"} }

import { NSpace, NLayout, NLayoutSider, NMenu,NBreadcrumb,NBreadcrumbItem } from 'naive-ui'
import type { Component } from 'vue'
import { defineComponent, h, ref ,onMounted} from 'vue'
import { NIcon } from 'naive-ui'
import {UserOutlined} from '@ant-design/icons-vue';
import { useRouter,useRoute } from 'vue-router'
import {getCurrentTimestamp} from "@/utils/solt/timestamp";
import {sortByMd5WithSalt} from "@/utils/solt";
import {fetchApplyAccount} from "@/api/apply";
import {message} from "ant-design-vue";
import {logoutUser} from "@/api/user";

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderCom',
  setup(__props) {

const router = useRouter()
const route=useRoute()
const isDropdownVisible = ref(false)
function showDropdown() {
  isDropdownVisible.value = true;
}
function hideDropdown() {
  isDropdownVisible.value = false;
}
function viewProfile (){
  router.push('/User')
}
async function logout (){
    const time = await getCurrentTimestamp()
    const obj = {
        timestamp: time
    }
    const sign = await sortByMd5WithSalt(obj)
    const res =await logoutUser({...obj,sign})
    if (res?.code == 200) {
        message.success(res?.msg)
        localStorage.removeItem('token')
        localStorage.removeItem('UserInfo')
        router.push('/login')
    }else{
        message.info(res?.msg)
    }
}
const menuList=ref({index:['首页'],countApply:['拿账户','开户申请'],countProgress:['拿账户','开户进度'],CountList:['拿账户','账户列表'],chargeProgress:['管账户','充值进度'],ChargeRecord:['管账户','充值记录'],Wallet:['管资金','钱包流水'],WalletProgress:['管资金','钱包充值进度'],WalletRecords:['管资金','账单管理'],
    ChargeReport:['管账户','账户消耗报告'],CompanyList:['企业信息','企业列表'],Team:['企业信息','团队管理'],CompanyInfo:['企业信息'],User:['帮助中心','我的资料']
})
onMounted(()=>{

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"display":"flex","align-items":"center","width":"258px","font-size":"25px","font-weight":"bold","justify-content":"center"} }, [
      _createElementVNode("img", {
        src: _imports_0,
        style: {"object-fit":"contain","height":"60px","margin-right":"20px"}
      }),
      _createElementVNode("div", null, "PanoMobi")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(NBreadcrumb), null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value[_unref(route).name], (item, i) => {
            return (_openBlock(), _createBlock(_unref(NBreadcrumbItem), { key: i }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "use_info",
        onMouseenter: showDropdown,
        onMouseleave: hideDropdown
      }, [
        _createVNode(_unref(UserOutlined)),
        (isDropdownVisible.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dropdown",
              onMouseenter: showDropdown,
              onMouseleave: hideDropdown
            }, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", { onClick: viewProfile }, "我的资料"),
                _createElementVNode("li", { onClick: logout }, "账号退出")
              ])
            ], 32))
          : _createCommentVNode("", true)
      ], 32)
    ])
  ]))
}
}

})